export function getKeepAlive(key){
    sessionStorage.getItem(key);
}

export function setKeepAlive(key,value){
    sessionStorage.setItem(key,value);
}
export function getSettingHomKeepAlive(){
    sessionStorage.getItem("ka-device-setting");
}
export function setSettingHomKeepAlivee(value){
    sessionStorage.setItem("ka-device-setting",value);
}
export function getAppType(){
    let u = navigator.userAgent;
    if(u){
        if(/(iPhone|iPad|iPod|iOS)/i.test(u)){
            return APP_TYPE_IOS;
        }else if(u.indexOf('Android') > -1 || u.indexOf('Adr') > -1){
            return APP_TYPE_ANDROID
        }
    }

    return APP_TYPE_IOS;
}
export const APP_TYPE_IOS = 1;
export const APP_TYPE_ANDROID = 2;

