import request from "@/util/request";

export function modifyPwd(data) {
    return request({
        url: '/user/pwd/modify/v2',
        method: 'put',
        data:data
    })
}

export function logout() {
    return request({
        url: '/user/logout',
        method: 'POST',
        data: {}
    })
}
export function updateUserInfo(data) {
    return request({
        url: '/user/info/update',
        method: 'put',
        data:data
    })
}
export function getUserInfo() {
    return request({
        url: '/user',
        method: 'get',
    })
}
export function userAuthResult(biztoken) {
    return request({
        url: '/tencentcloudapi/result',
        method: 'POST',
        data:{biztoken:biztoken,isH5:true}
    })
}
export function accountoff() {
    return request({
        url: '/user/del',
        method: 'POST',
    })
}
