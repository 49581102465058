<template>
  <div style="margin: 10px">
    <div style="text-align: center;font-size: 24px;margin: 20px 0px">
      {{$t('注销账号提示')}}
    </div>
    <div style="width: 100%;font-size: 14px;line-height: 24px">
      <div>{{$t('账号注销提示1')}}</div>
      <div style="font-size: 18px;font-weight:bolder;padding: 10px 0px">{{$t('账号注销提示2')}}</div>
      <div style="font-size: 18px;font-weight:bolder;padding: 10px 0px">
        {{$t('账号注销提示3')}}
      </div>
      <div>
        {{$t('账号注销提示4')}}
        <br/>
        {{$t('账号注销提示5')}}
      </div>
      <div style="font-size: 18px;font-weight:bolder;padding: 10px 0px">
        {{$t('账号注销提示6')}}
      </div>
      <div style="text-align: center;margin-top: 50px">
        <van-button @click="doAccountoff" type="info">{{$t('账号注销提示7')}}</van-button>
      </div>

    </div>
  </div>
</template>

<script>
import {Button, Toast} from 'vant';
import {accountoff} from "../../../api/user";
import {navigateToLogin} from "../../../util/jsinterfaces-util";
export default {
  name: "index",
  components:{
    [Button.name]: Button,
  },
  methods:{
    doAccountoff(){
      accountoff().then(() => {
        Toast.success({message:this.$t('账号注销成功'),position:"top"})
        setTimeout(navigateToLogin,2000)
      })
    }
  }
}
</script>

<style scoped>

</style>
