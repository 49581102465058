import {APP_TYPE_ANDROID, APP_TYPE_IOS, getAppType} from "./common-utils";
import {Notify} from "vant"
export function navigateToDeviceList(){
    navigateTo("/device/list","{}")
}

export function navigateToDeviceShare(imei){
    let param = {imei:imei}
    navigateTo("/device/share",JSON.stringify(param))
}
export function navigateToUserAuth(){
    navigateTo("/user/auth","{}")
}
export function navigateToFenceList(){
    navigateTo("/fence/list","{}")
}
export function navigateToLogin(){
    navigateTo("/login","{}")
}
export function navigateToSimcard(){
    navigateTo("/simcard","{}")
}
export function navigateToRenewal(imei){
    navigateTo("/device/renewal",JSON.stringify({imei:imei}))
}
export function navigateToDeviceEdit(imei){
    navigateTo("/device/edit",JSON.stringify({imei:imei}))
}
export function navigateToDeviceMapOverview(){
    navigateTo("/device/map/overview",{})
}

export function navigateToDeviceAdd(){
    navigateTo("/device/add","{}")
}
export function navigateToLocationTrack(imei){
    navigateTo("/location/track",JSON.stringify({imei:imei}))
}
export function navigateToLocationHistory(imei){
    navigateTo("/location/history",JSON.stringify({imei:imei}))
}
export function navigateToIndex(imei){
    navigateTo("/index",JSON.stringify({currentImei:imei}))
}
export function changeDevice(imei,name,avatar){
    let appType = getAppType();
    // Notify({type: 'danger', message: "appType:" + appType});
    if(appType == APP_TYPE_IOS) {
        //IOS
        try {
            window.$bridge.callhandler('changeDevice', {imei,name,avatar}, () => {
            })
        }catch (e) {
            Notify({type: 'danger', message: e.message});
        }
    }else if(appType == APP_TYPE_ANDROID){
        //Android
        window.app.changeDevice(imei,name,avatar);
    }
}
export function getCurrentDeviceImei(callback){
    let appType = getAppType();
    if(appType == APP_TYPE_IOS){
        //IOS
        try {
            window.$bridge.callhandler('getDevice', {}, callback)
        }catch (e){
            window.console.log(e)
        }

    }else if(appType == APP_TYPE_ANDROID){
        //Android
        let imei = window.app.getDevice()
        callback(imei);
    }

}

export function navigateTo(url,param,callback){
   let appType = getAppType();
    if (appType == APP_TYPE_IOS) {
        //IOS
        window.$bridge.callhandler('navigateTo', {url,param}, callback || (() => {
        }))
    } else if (appType == APP_TYPE_ANDROID) {
        //Android
        let result = window.app.navigateTo(url, param)
        if(callback){
            callback(result);
        }
    } else {
        // Notify({type: 'danger', message: "未知环境"});
    }

}

export function changeLanguage(language){
    let appType = getAppType();
    // Notify({type: 'danger', message: "appType:" + appType});
    if(appType == APP_TYPE_IOS) {
        //IOS
        try {
            window.$bridge.callhandler('changeLanguage', language, () => {
            })
        }catch (e) {
            Notify({type: 'danger', message: e.message});
        }
    }else if(appType == APP_TYPE_ANDROID){
        //Android
        window.app.changeLanguage(language)
    }
}
export function orderPay(orderNo,payType){
    let appType = getAppType();
    if(appType == APP_TYPE_IOS) {
        //IOS
        window.$bridge.callhandler('orderPay',{orderNo:orderNo,payType:payType}, () => {
        })
    }else if(appType == APP_TYPE_ANDROID){
        //Android
        window.app.orderPay(orderNo,payType);
    }
}


